// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-featured-recipes-js": () => import("./../src/pages/featured-recipes.js" /* webpackChunkName: "component---src-pages-featured-recipes-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-js": () => import("./../src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */),
  "component---src-pages-map-js": () => import("./../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-recipes-js": () => import("./../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-templates-recipe-js": () => import("./../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-week-js": () => import("./../src/templates/week.js" /* webpackChunkName: "component---src-templates-week-js" */)
}

